<template>
  <v-container class="login-container" fluid fill-height>
    <v-layout align-center class="justify-center">
      <div>
        <v-layout align-center column>
          <v-card class="login-card justify-center rounded-lg">
            <v-form
              ref="form"
              v-model="valid"
              @submit.prevent="submit"
              lazy-validation
            >
              <v-img 
                src="@/assets/logo_jamworks.svg" 
                class="logo"
                contain 
                height="21.58px"
              />

              <v-text-field
                :label="$t('email')"
                type="email"
                v-model="email"
                :rules="emailRules"
                prepend-inner-icon="mdi-email-outline"
                required
                autofocus
                outlined
              ></v-text-field>

              <v-text-field
                :label="$t('password')"
                v-model="passwd"
                :rules="passwdRules"
                type="password"
                prepend-inner-icon="mdi-lock-outline"
                required
                outlined
              ></v-text-field>

              <v-btn
                :disabled="loading"
                class="primary"
                large
                block
                type="submit"
                :loading="loading"
              >
                <span>
                  {{ $t('login') }}
                </span>
              </v-btn>
            </v-form>

            <v-bottom-navigation
              class="bottom-nav"
              absolute
            >
              <a @click="openForgotPasswordModal">
                {{ $t('forgot_password') }}
              </a>
            </v-bottom-navigation>

          </v-card>
        </v-layout>
       </div>
    </v-layout>

    <DefaultModal 
      :title="$t('reset_passwd_email_title')"
      @submit="forgotPassword"
      ref="resetPasswdModal"
      submit-button-text="send"
    >
      <template v-slot:content>
          <v-text-field
            :label="$t('email')"
            v-model="forgotPasswdEmail"
            :rules="emailRules"
            outlined
            dense
          ></v-text-field>
        </template>
    </DefaultModal>

  </v-container>
</template>

<style>
  .login-card {
    padding: 60px 35px 35px;
    width: 360px;
    max-width: 90vw;
    height: 70vh;
    max-height: 610px;
    min-height: 420px;
  }

  .errorMessage {
    margin-top: 20px;
    color: var(--v-error-base);
    text-align: center;
  }

  .login-container {
    background: url('/img/login_background.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .logo {
    margin-bottom: 40px;
  }

  .bottom-nav {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    align-items: center;
    font-size: 12px
  }
</style>

<script>
import { mapActions } from 'vuex'

import DefaultModal from '@/components/DefaultModal.vue'

import AuthActions from "@/store/core/auth/actions-types";
import AppActions from '@/store/app/actions-types'
import router from "@/router";

export default {
  name: "Login",

  components: {
    DefaultModal,
  },

  data() {
    return {
      valid: false,
      loading: false,
      email: null,
      passwd: null,
      emailRules: [
        v => !!v || this.$t('form_rules.mandatory'),
        v => /.+@.+\..+/.test(v) || this.$t('form_rules.invalid_email'),
      ],
      passwdRules: [
        v => !!v || this.$t('form_rules.mandatory'),
        v => (v && v.length > 3) || this.$t('form_rules.min_length', {name: this.$t('password'), min: "4"}),
      ],
      forgotPasswdEmail: null,
    };
  },

  methods: {
    ...mapActions('core/auth', [
      AuthActions.DO_LOGIN,
      AuthActions.RESET_PASSWORD,
    ]),

    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
    ]),

    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;
      const credentials = {username: this.email, password: this.passwd};

      this.[AuthActions.DO_LOGIN](credentials)
        .then(() => {
          router.push(this.$store.state.core.auth.lastPath ?? '/')
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        })
    },

    openForgotPasswordModal() {
      this.$refs.resetPasswdModal.openModal()
    },

    forgotPassword() {
      this.[AuthActions.RESET_PASSWORD]({email: this.forgotPasswdEmail, link: this.$el.baseURI})
        .then(() => {
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('email_sent_successfully'))

          this.$refs.resetPasswdModal.closeModal()
        })
        .catch(() => {
          this.$refs.resetPasswdModal.closeModal()
        })
    },
  }
};
</script>
